.product-container {
    width: 100%;
    height: 100%;
    padding: 10vh 5vw;
    /* overflow: hidden; */
    display: flex;
    gap: 3rem;
   
    /* background-color: #F2F2F2; */
}

.visit-div{
    margin-top: 3rem;
    align-items: center;
    display: flex;
    gap: 1rem;
}

.visit-button{
    font-size: 2rem;
}

.daraz-div{
    width: inherit;
    height: inherit;
    border-radius: 10px;
    margin-left: 10%;
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    
}

.daraz-div img{
    width: 8rem;
    object-fit: fill;
    height: 6rem;   
    
    clip-path: circle(50.3% at 50% 50%)
}

.visit-use{
    
    letter-spacing: 2px;
}
.product-pics {
    width: 50%;
    height: 100%;
    margin-top: 10px;
}

.product-pics img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
}
/* Adjust Carousel styles */

.carousel:first-child {
    max-height: 86vh;
    box-shadow: -1px -2px 5px 0px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: -1px -2px 5px 0px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: -1px -2px 5px 0px rgba(0, 0, 0, 0.7);
}

.product-details {
    width: 50%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 7%;
}

.product-title {
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.heading {
    font-size: 3rem;
    font-family: var(--font-monSemi);
}

.rating {
    display: flex;
    justify-content: space-between;
}

.rating-count {
    margin-right: 5%;
    font-size: 17px;
    
}

.buying-part {
    display: flex;
    
}

.buying-left {
    display: flex;
    flex-direction: column;
    margin-top: 1%;
    gap: 1rem;
}

.buying-right {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: 5%;
    margin-top: 1%;
}

.buying-right button {
    padding: 3px 20px;
    cursor: pointer;
    margin-top: 2%;
    font-size: large;
    border-radius: 2px;
    
    background-color: #AB957D;
    border: none;
    outline: none;
    box-shadow: -1px -2px 5px 0px rgba(0, 0, 0, 0.7);
    -webkit-box-shadow: -1px -2px 5px 0px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: -1px -2px 5px 0px rgba(0, 0, 0, 0.7);
}

.ocp {
    font-size: 1.5rem;
    letter-spacing: 1px;
    
}

.product-desc {
    margin-top: 3rem;
    font-size: 1.5rem;
    letter-spacing: 1px;
}

.product-desc.product-note{
    color: #837260;
    font-size: 1.2rem;
}
/* ... Your existing CSS ... */

/* Responsive adjustments for screens with a maximum width of 600px */
@media (max-width: 600px) {
    .product-container {
        flex-direction: column; /* Stack items vertically for smaller screens */
        padding: 5vh 2vw; /* Adjusted padding for smaller screens */
    }

    .product-pics {
        width: 100%;
        margin-top: 0; /* Remove top margin for smaller screens */
    }

    .carousel:first-child {
        max-height: 60vh; /* Adjusted max height for smaller screens */
    }

    .product-details {
        width: 100%;
        padding: 10px;
        gap: 5%; /* Adjusted gap for smaller screens */
    }

    .product-title {
        gap: 1rem; /* Adjusted gap for smaller screens */
    }

    .heading {
        font-size: 2rem; /* Adjusted font size for smaller screens */
    }

    .buying-part {
        flex-direction: column; /* Stack items vertically for smaller screens */
    }

    .buying-left {
        margin-top: 0; /* Remove top margin for smaller screens */
        gap: 0.5rem; /* Adjusted gap for smaller screens */
    }

    .buying-right {
        margin-right: 0; /* Remove right margin for smaller screens */
        gap: 5px; /* Adjusted gap for smaller screens */
        margin-top: 0.5rem; /* Adjusted margin for smaller screens */
    }

    .buying-right button {
        margin-top: 2%; /* Adjusted top margin for smaller screens */
    }

    .ocp {
        font-size: 1rem; /* Adjusted font size for smaller screens */
    }

    .product-desc {
        margin-top: 2rem; /* Adjusted top margin for smaller screens */
        font-size: 1rem; /* Adjusted font size for smaller screens */
    }
}

