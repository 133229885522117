.product-display-container{
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
}

.pd-top{
    position: relative;
    background-repeat: no-repeat;
    background-size: cover; /* Ensure the image covers the entire container */
    clip-path: polygon(50% 0%, 100% 0, 100% 60%, 50% 100%, 50% 100%, 0% 60%, 0 0);  
    background-position: center center; 
    width: 100%;
    height: 45vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.pd-top h1{
    font-size: 3rem;
    font-family: var(--font-monSemi);
    letter-spacing: 1px;
    text-transform: capitalize;
    
    color: rgb(248, 248, 248);
    margin-bottom: 2%;
    font-weight: bolder;
}

.pd-top p{
    font-size: 2rem;
    text-align: center;
    font-weight: 900;
    letter-spacing: 1px;
    color: rgb(99, 15, 15);
}

.pd-bottom{
    padding: 0 0vw;
    padding-bottom: 100px;
}

/* shop css */

.shopTitles {
    /* margin-top: 100px; */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    font-family: var(--font-monSemi);
    font-size: 2rem;
    margin-bottom: 3%;
  }
  .shop-logo{
    height: 10rem;
  }
  .productss {
    width: 100%;
    height: 100%;
    
    display: grid;
	
    
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(500px, 300px);
    grid-auto-flow: dense;
    place-items: center;
  }
  
  .products {
    border-radius: 15px;
    width: 250px;
    height: 350px;
    margin: 100px;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .products img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .products .descriptions {
    text-align: center;
  }
  
  .products:hover {
    transition: 0.3s ease-in;
    cursor: pointer;
  }
  
  .addToCartBttns {
    background-color: transparent;
    border: 2px solid rgb(19, 19, 19);
    min-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 15px;
  }
  
  .addToCartBttns:hover {
    background-color: rgb(19, 19, 19);
    color: white;
    cursor: pointer;
  }
  
/* ----------------------------------COOL UI---------------------------------- */
.card {
  width: 300px;
  min-height: 400px;
  height: auto;
  padding: .8em;
  background: #f5f5f5;
  position: relative;
  overflow: visible;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
 }
 
 .card-img {
  background-color: #ffcaa6;
  height: 65%;
  cursor: pointer;
  width: 100%;
  border-radius: .5rem;
  transition: .3s ease;
 }

 .card-img img{
  width: 100%;
  height: 100%;
 }
 
 .card-info {
  padding-top: 10%;
 }
 
 svg {
  width: 20px;
  height: 20px;
 }
 
 .card-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  border-top: 1px solid #ddd;
 }
 
 /*Text*/
 .text-titlee {
  font-weight: 900;
  font-size: 1.2em;
  line-height: 1.5;
 }
 
 .text-body {
  font-size: .9em;
  padding-bottom: 10px;
 }
 
 /*Button*/
 .card-button {
  border: 1px solid #252525;
  display: flex;
  align-items:  center;
  justify-content: center;
  padding: .5rem 1rem;
  cursor: pointer;
  border-radius: 50px;
  transition: .3s ease-in-out;
 }
 
 /*Hover*/
 .card-img:hover {
  transform: translateY(-5%);
  box-shadow: rgba(226, 196, 63, 0.25) 0px 13px 47px -5px, rgba(180, 71, 71, 0.3) 0px 8px 16px -8px;
 }
 
 .card-button:hover {
  border: 1px solid #ffcaa6;
  background-color: #ffcaa6;
 }
 
  @media (max-width: 600px) {
    .pd-top {
        height: 40vh; /* Adjusted height for smaller screens */
    }

    .pd-top h1 {
        font-size: 2rem; /* Adjusted font size for smaller screens */
    }

    .pd-top p {
        margin-bottom: 4%;
        font-size: .9rem; /* Adjusted font size for smaller screens */
    }

    .pd-bottom {
        padding-bottom: 50px; /* Adjusted padding for smaller screens */
        padding-right: 3rem;
    }

    .shop-logo{
      height: 5rem;
    }

    .productss {
        margin-left: 1.5rem;
        
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(450px, auto);
    }
    

    .products {
        border-radius: 15px;
        width: 200px;
        height: 350px;
        padding-right: 2.5rem !important;
        display: flex;
        
      }
      
      .products img {
        width: 300px;
      }

      .card {
        width: 300px;
        min-height: 400px;
        height: auto;
        padding: .8em;
        background: #f5f5f5;
        position: relative;
        overflow: visible;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
       }
       
       .card-img {
        background-color: #ffcaa6;
        height: 65%;
        cursor: pointer;
        width: 100%;
        border-radius: .5rem;
        transition: .3s ease;
       }
      
       .card-img img{
        width: 100%;
        height: 100%;
       }
       
       .card-info {
        padding-top: 10%;
       }
       
       svg {
        width: 20px;
        height: 20px;
       }
       
       .card-footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        border-top: 1px solid #ddd;
       }
       
       /*Text*/
       .text-title {
        font-weight: 900;
        font-size: 1.2em;
        line-height: 1.5;
       }
       
       .text-body {
        font-size: .9em;
        padding-bottom: 10px;
       }
       
       /*Button*/
       .card-button {
        border: 1px solid #252525;
        display: flex;
        align-items:  center;
        justify-content: center;
        padding: .5rem 1rem;
        cursor: pointer;
        border-radius: 50px;
        transition: .3s ease-in-out;
       }
       
       /*Hover*/
       .card-img:hover {
        transform: translateY(-25%);
        box-shadow: rgba(226, 196, 63, 0.25) 0px 13px 47px -5px, rgba(180, 71, 71, 0.3) 0px 8px 16px -8px;
       }
       
       .card-button:hover {
        border: 1px solid #ffcaa6;
        background-color: #ffcaa6;
       }
}