.project-display-model{
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    overflow-x: hidden;
}

.project-display-model-container{
    padding: 5vh 9vw;
}

.project-display-model-arrow {
    font-size: 2rem;
    animation: bounce 2s infinite;
  }

  .project-categories.model{
    
    
  }

@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px);
    }
  }

  @media (max-width: 600px) {
    .project-display-model-container{
        padding: 3rem 1rem;
    }
    .project-categories.model{
    
        margin-bottom: 20%;
    }
  }