.project-display-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: hidden;
    /* align-items: center; */
    color: white;
    height: 100%;
    width: 100%;
    background-color: #f2f2f2;
    /* Set your desired background color */
}

.project-slider {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    height: 100vh;
    /* Set your desired width for the slider container */
    /* max-width: 1200px; */
    /* Set the maximum width if needed */
}

.swiper-slide {
    width: 100%;
    height: 100vh;
    /* Set your desired height for the slides */
    object-fit: cover;
    object-position: center center;
    
}

.mySwiper2 {
    width: 100%;
    
}

.swiper-bottom {
    margin-top: 5px;
    width: 100%;
    gap: 5px !important;
    display: flex;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.6)
    );
}

.swiper-button-next,
.swiper-button-prev {
    opacity: 0;
}

.swiper-main-image img {
    width: 100%;
    height: 100%;
    object-fit: auto;
    object-position: center;
}

.swiper-bottom .swiper-slide {
    width: 80px;
    /* Width of the slide */
    height: 115px;
    /* Height of the slide */
    object-fit: cover;
    /* Maintain aspect ratio; cover the area completely */
    margin-right: 5px;
    
    /* Space between slides */
}

.swiper-bottom img {
    width: 100%;
    /* Ensure the image fills the slide container */
    height: 100%;
    /* Ensure the image fills the slide container */
    object-fit: cover;
    /* Maintain aspect ratio; cover the area completely */
    object-position: center;
    /* Center the image within the slide */
}

.project-display-desc {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
}

.project-display-main-desc {
    width:100%;
    height: 100%;
    margin-bottom: 5%;
    /* transform: translateX(5%); */
    
    display: flex;
    margin-top: 1%;
    justify-content: space-between;
    /* background-color: aquamarine; */
}

.project-display-left,
.project-display-right {
    width: 100%;
    height: 100%;
    display: flex;

    padding: 20px 0px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}


.location,
.status,
.project-share {
    
    display: flex;
    gap: 1rem
}

.pro-brief {
    width: 23vw;
    
}

.project-display-left p {
    font-family: 'Montserrat', sans-serif;
    /* text-transform: uppercase; */
    letter-spacing: 2px;
    font-weight: 100;
    color: black;
}


.video-display{
    width: 100%;
    height: 100%;
    padding: 1rem 5vw;
    margin-bottom: 100px;
    
}

.project-photos {
    width: 100%;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  .project-photos h1{
    color: black;
  }

  .project-photos img{
    width: 33%;
    max-height: 400px;
  }
 

@media (max-width: 600px) {
    .project-display-main-desc {
        flex-direction: column; /* Stack columns on top of each other */
        align-items: center;
        width: 100%;
        transform: translateX(0%);
    }

    .project-display-desc{
        margin-top: -10%;
    }

    .project-photos img{
        width: 45%;
      }
    
    .swiper-main-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}