.contact-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    
 
    gap: 1.5rem;
    width: 100%;
    
    background-color: #f2f2f2;
}

.contact-middle{
    width: 100%;
    height: 100%;
    
    padding: 5rem 5vw;
    display: flex;
    flex-wrap: wrap;
    
}

.contact-middle-left{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    
}

.contact-middle-left h1{
    font-family: 'Island Moments';
    letter-spacing: 2px;
    font-size: 2rem;
    color:black;
}

.contact-middle-left p{
    color: black;
    font-size: 1.4rem;
    width: 80%;
}

.contact-icons{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.contact-icon-deco{
    width: 2.5rem;
    height: 2.5rem;
    /* padding: 5px; */
    border-radius: 50%;
    /* background-color: rgba(194, 10, 10, 0.877); */
    border: 2px solid green;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actual-icon{
    font-size: 1.3rem;
    border-radius: 50%;
    color: black;
}

.icon-desc{
    font-size: 1.2rem !important;
}

.booking-title{
    width: 100%;
    height: 60vh;
    background-image: url('../../imgs/back1.jpg');
    clip-path: polygon(50% 0%, 100% 0, 100% 60%, 50% 100%, 50% 100%, 0% 60%, 0 0);  
    display: flex;
    align-items: center;
    font-weight: 900;
    justify-content: center;
    background-size: auto; /* Ensure the image covers the entire container */
    background-position: center center; 
    
}

.contact-text{
    font-family: var(--font-monSemi);
    font-size: 4rem;
    text-decoration: underline;
    text-align: center;
    color: rgb(37, 35, 35);
}

.contact-middle-right{
    height: 25rem;
    
    border-radius: 5px;
    padding: 2rem 1rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    
}

.contact-middle-right img{
    width: 100%;
    height: 130%;

    transform: translate(10% ,-10%);
    
}

.contact-middle-right input{
    padding: .5rem 1rem;
    border: none;
    outline: none;
}

.contact-middle-right textarea{
    resize: none;
    padding: 1rem 1rem;
    border: none;
    outline: none;
}

.contact-button{
    width: 40%;
    height: 3rem;
    padding: .5rem 0rem;
}

@media (max-width: 600px) {
    .contact-middle{
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    .contact-middle-left {
        width: 100%;
    }

    .contact-middle-right {
        width: 100%;
    }

    .contact-middle-left p{
        
        width: 100%;
    }

    .contact-middle-right img{
        width: 100%;
        height: 120%;
    
        transform: translateY(0%);
    }

    .contact-middle-right input,
    .contact-middle-right textarea {
        width: auto;
    }
}